<template>
    <!--岗位职责-->
    <div>
        <!-- <el-dialog :title="'岗位说明书新'" :visible.sync="dlgVisible" v-model="dlgVisible"
                   :close-on-click-modal="false"> -->
            <el-dialog :title="operation ? '添加' : (optType?'编辑':'查看')" :visible.sync="dlgVisible" v-model="dlgVisible"
           :close-on-click-modal="false">
            <el-form ref="dlgFrom" :model="dlgFrom" class="demo-form-inline" label-width="100px">
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>一、基本资料</span></div>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="公司:" prop="Factory">
                            <el-input type="text" v-model="dlgFrom.Factory" :disabled="true"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="版本:" prop="VersionsNo">
                            <el-input type="text" v-model="dlgFrom.VersionsNo"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="隶属部门:" prop="DptName">
                            <el-input type="text" v-model="dlgFrom.DptName" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="分析日期:" prop="ApprovalDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-date-picker type="date" v-model="dlgFrom.ApprovalDate" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 100%"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位名称:" prop="PostName">
                            <el-input type="text" :disabled="true" v-model="dlgFrom.PostName">
                                <el-button slot="append" icon="el-icon-search" @click="chosePost"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="display:none;">
                        <el-form-item label="岗位ID:" prop="PostId">
                            <el-input type="text" v-model="dlgFrom.PostId"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="定员标准:" prop="PersonnelStandard">
                            <el-input type="text" v-model="dlgFrom.PersonnelStandard"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="直接上级岗位:" prop="PostSuperiorsName">
                            <el-input type="text" v-model="dlgFrom.PostSuperiorsName"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="直接上级岗位id:" prop="PostSuperiorsId">
                            <el-input type="text" v-model="dlgFrom.PostSuperiorsId"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="直接下级岗位:" prop="PostSubordinateName">
                            <el-input type="text" v-model="dlgFrom.PostSubordinateName"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="直接下级岗位Id:" prop="PostSubordinateId">
                            <el-input type="text" v-model="dlgFrom.PostSubordinateId"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位序列:" prop="PostSequence">
                            <el-input type="text" v-model="dlgFrom.PostSequence"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职级范围:" prop="RankRange">
                            <el-input type="text" v-model="dlgFrom.RankRange"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>二、岗位职责</span></div>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（一）职责概述</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="职责概述" prop="PostSummarize" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="textarea" v-model="dlgFrom.PostSummarize" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（二）工作职责（按重要性排序）</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.Details" style="width: 100%">
                        <el-table-column align="center" label="工作职责" prop="Overview"> </el-table-column>
                        <el-table-column align="center" label="具体任务" prop="Describe"> </el-table-column>
                        <el-table-column align="center" label="频次" prop="Period"> </el-table-column>
                        <el-table-column align="center" label="工作依据的标准文件/流程" prop="Standard"> </el-table-column>
                        <el-table-column align="center" label="输出资料" prop="Material"> </el-table-column>
                        <el-table-column align="right" >
                            <template slot="header" slot-scope="scope">
                                <el-button size="mini" @click="ShowDetails()" >添加</el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-button size="mini" @click="EditDetails(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelDetails(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>三、岗位权限</span></div>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.AuthorityArry" style="width: 100%">
                        <el-table-column align="center" label="岗位权限" prop="content"> </el-table-column>
                        <el-table-column align="right">
                            <template slot="header" slot-scope="scope">
                                <el-button size="mini" @click="ShowAuthority()">添加</el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-button size="mini" @click="EditAuthority(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelAuthority(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>四、监督及岗位关系</span></div>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（一）所受监督和所施监督</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="1、所受监督:" prop="BeSupervised">
                            <el-input type="text" v-model="dlgFrom.BeSupervised"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="2、所施监督:" prop="Supervised">
                            <el-input type="text" v-model="dlgFrom.Supervised"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（二）与其他岗位关系</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="1、内部联系:" prop="PostPartner">
                            <el-input type="text" v-model="dlgFrom.PostPartner"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="2、外部联系:" prop="ExternalDpt">
                            <el-input type="text" v-model="dlgFrom.ExternalDpt"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（三）本岗位职务晋升/工作专业发展路径（文字描述）</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="管理职务发展通道" prop="ManagementDevelopmentPath">
                            <el-input type="textarea" v-model="dlgFrom.ManagementDevelopmentPath"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="专业职级发展通道" prop="ProfessionalDevelopmentPath">
                            <el-input type="textarea" v-model="dlgFrom.ProfessionalDevelopmentPath"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（四）本岗位横向平移情况/工作横向发展方向</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="可调整调动的类似岗位方向" prop="AdjustablePosition">
                            <el-input type="textarea" v-model="dlgFrom.AdjustablePosition"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>五、劳动条件和环境</span></div>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="劳动条件和环境" prop="LaborCondition">
                            <el-input type="textarea" v-model="dlgFrom.LaborCondition" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>六、工作时间</span></div>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作时间" prop="WorkingTime">
                            <el-input type="textarea" v-model="dlgFrom.WorkingTime"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>七、岗位资历及素质要求</span></div>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性格类型:" prop="AstrictCharacter">
                            <el-input type="text" v-model="dlgFrom.AstrictCharacter"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别:" prop="AstrictSex">
                            <el-select v-model="dlgFrom.AstrictSex" placeholder="请选择" style="width: 100%">
                                <el-option label="男优先" value="男优先"></el-option>
                                <el-option label="女优先" value="女优先"></el-option>
                                <el-option label="不限 " value="不限"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专业:" prop="AstrictSpecialty">
                            <el-input type="text" v-model="dlgFrom.AstrictSpecialty"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <!-- <el-form-item label="年龄:" prop="AstrictAge" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictAge" placeholder="请选择" style="width: 100%">
                                <el-option label="25岁以下" value="25岁以下"></el-option>
                                <el-option label="26-35岁" value="26-35岁"></el-option>
                                <el-option label="36-45岁" value="36-45岁"></el-option>
                                <el-option label="45岁以上" value="45岁以上"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="年龄:" prop="AstrictAge">
                            <el-input type="text" v-model="dlgFrom.AstrictAge"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学历:" prop="AstrictEdu">
                            <el-input type="text" v-model="dlgFrom.AstrictEdu"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="特别要求:" prop="OtherRequire">
                            <el-input type="text" v-model="dlgFrom.OtherRequire"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="品德要求:" prop="MoralityRequire">
                            <el-input type="text" v-model="dlgFrom.MoralityRequire"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="经验要求:" prop="ExpRequire">
                            <el-input type="text" v-model="dlgFrom.ExpRequire"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                 <el-row>
                    <el-col :span="24">
                        <el-form-item label="素质要求描述" prop="QualityRequire" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="textarea" v-model="dlgFrom.QualityRequire" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>八、所需知识和专业技能</span></div>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（一）担任本岗位职责应具备的专业知识</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.KnowledgeRequireArry" style="width: 100%">
                        <el-table-column align="center" label="专业知识" prop="content"> </el-table-column>
                        <el-table-column align="right">
                            <template slot="header" slot-scope="scope">
                                <el-button size="mini" @click="ShowKnowledgeRequireArry()">添加</el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-button size="mini" @click="EditKnowledgeRequireArry(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelKnowledgeRequireArry(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（二）担任本岗位职责应具备的专业技能</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.SkillRequireArry" style="width: 100%">
                        <el-table-column align="center" label="专业技能" prop="content"> </el-table-column>
                        <el-table-column align="right">
                            <template slot="header" slot-scope="scope">
                                <el-button size="mini" @click="ShowSkillRequireArry()">添加</el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-button size="mini" @click="EditSkillRequireArry(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelSkillRequireArry(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div>（三）担任本岗位职责应具备的管理知识和能力</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.ManageRequireArry" style="width: 100%">
                        <el-table-column align="center" label="管理知识和能力" prop="content"> </el-table-column>
                        <el-table-column align="right">
                            <template slot="header" slot-scope="scope">
                                <el-button size="mini" @click="ShowManageRequireArry()">添加</el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-button size="mini" @click="EditManageRequireArry(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelManageRequireArry(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>九、本岗位工作考核的关键内容、指标（3-5项即可）</span></div>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.AssessItemArry" style="width: 100%">
                        <el-table-column align="center" label="考核内容、指标" prop="content"> </el-table-column>
                        <el-table-column align="right">
                            <template slot="header" slot-scope="scope">
                                <el-button size="mini" @click="ShowAstricts()">添加</el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-button size="mini" @click="EditAstricts(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelAstricts(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>

                <el-row v-if="optType">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('dlgFrom')">提交</el-button>
                    </el-col>
                </el-row>
                <el-row v-if="optType1">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit1('dlgFrom')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="addPostVisible" v-model="addPostVisible" width="75%" center append-to-body>
            <UserChoosejob :data="choosedPosts" :all="true" :single="false" @callback="chosePostcallFunction"></UserChoosejob>
        </el-dialog>
        <el-dialog :visible.sync="chooseJobVisibleUp" v-model="chooseJobVisibleUp" width="75%" center append-to-body>
            <UserChoosejob :data="chooseJobUp" :all="true" :single="false" @callback="chooseJobUpPostcallFunction"></UserChoosejob>
        </el-dialog>
        <el-dialog :visible.sync="chooseJobVisibleDown" v-model="chooseJobVisibleDown" width="75%" center append-to-body>
            <UserChoosejob :data="chooseJobDown" :all="true" :single="false" @callback="chooseJobDownPostcallFunction"></UserChoosejob>
        </el-dialog>

        <el-dialog title="工作职责" :visible.sync="AddDetailsVisible" v-model="AddDetailsVisible" center append-to-body>
            <el-form label-width="150px" ref="AddDetails" :model="AddDetails">
                <el-form-item label="工作职责:" prop="Overview">
                    <el-input v-model="AddDetails.Overview"></el-input>
                </el-form-item>
                <el-form-item label="具体任务:" prop="Describe">
                    <el-input type="textarea" v-model="AddDetails.Describe"></el-input>
                </el-form-item>
                
                <el-form-item label="频次:" prop="Period">
                    <el-select v-model="AddDetails.Period" placeholder="请选择" style="width: 100%">
                        <el-option label="每天" value="每天"></el-option>
                        <el-option label="每星期" value="每星期"></el-option>
                        <el-option label="每月" value="每月"></el-option>
                        <el-option label="每季度" value="每季度"></el-option>
                        <el-option label="每年" value="每年"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工作依据的标准文件/流程:" prop="Standard">
                    <el-input type="textarea" v-model="AddDetails.Standard"></el-input>
                </el-form-item>
                <el-form-item label="输出资料:" prop="Material">
                    <el-input type="textarea" v-model="AddDetails.Material"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddDetailsVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="DetailsSubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="考核内容、指标" :visible.sync="AddAstrictsVisible" v-model="AddAstrictsVisible" center append-to-body>
            <el-form label-width="150px" ref="AddAstricts" :model="AddAstricts">
                <el-form-item label="考核内容、指标:" prop="content">
                    <el-input v-model="AddAstricts.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddAstrictsVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="AstrictsSubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="岗位权限" :visible.sync="AddAuthorityVisible" v-model="AddAuthorityVisible" center append-to-body>
            <el-form label-width="150px" ref="AddAuthority" :model="AddAuthority">
                <el-form-item label="岗位权限:" prop="content">
                    <el-input v-model="AddAuthority.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddAuthorityVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="AuthoritySubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- KnowledgeRequireArry -->
        <el-dialog title="专业知识" :visible.sync="AddKnowledgeRequireArryVisible" v-model="AddKnowledgeRequireArryVisible" center append-to-body>
            <el-form label-width="150px" ref="AddKnowledgeRequireArry" :model="AddKnowledgeRequireArry">
                <el-form-item label="专业知识:" prop="content">
                    <el-input v-model="AddKnowledgeRequireArry.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddKnowledgeRequireArryVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="KnowledgeRequireArrySubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- SkillRequireArry -->
        <el-dialog title="专业技能" :visible.sync="AddSkillRequireArryVisible" v-model="AddSkillRequireArryVisible" center append-to-body>
            <el-form label-width="150px" ref="AddSkillRequireArry" :model="AddSkillRequireArry">
                <el-form-item label="专业技能:" prop="content">
                    <el-input v-model="AddSkillRequireArry.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddSkillRequireArryVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="SkillRequireArrySubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- ManageRequireArry -->
        <el-dialog title="管理知识和能力" :visible.sync="AddManageRequireArryVisible" v-model="AddManageRequireArryVisible" center append-to-body>
            <el-form label-width="150px" ref="AddManageRequireArry" :model="AddManageRequireArry">
                <el-form-item label="管理知识和能力:" prop="content">
                    <el-input v-model="AddManageRequireArry.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddManageRequireArryVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="ManageRequireArrySubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
            <UserChoose :data="choosedusers" :all="true" :single="false" @callback="checkupUsercallFunction"></UserChoose>
        </el-dialog>
    </div>
</template>

<script>
    import UserChoosejob from "../../components/UserChoosejob";
    import UserChoose from "../../components/UserChoose";
    import {
        AddPostExplainInfo,
        GetPostExplainInfo,
        UpdatePostExplainInfo,
        GetInfoByPostId,
        GetDepartmentOrg,
        AddInfoForTeam,
    } from "../../api/hr";
    export default {
        components: { UserChoosejob, UserChoose },
        data() {
            return {
                optType: false,   // 编辑/查看
                optType1: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑

                dlgVisible: false, // dlg 显示隐藏
                dlgFrom: {
                    Factory: "",
                    DptName: "",
                    PostId: "",
                    PostName: "",
                    PostSuperiorsName: "",
                    PostSuperiorsId: "",
                    PostSubordinateId: "",
                    PostSubordinateName: "",
                    Details: {},
                    AssessItemArry: {},
                    AuthorityArry:{},
                    KnowledgeRequireArry:{},
                    SkillRequireArry:{},
                    ManageRequireArry:{},

                },

                addPostVisible: false,
                choosedPosts: [],

                chooseJobVisibleUp: false,
                chooseJobUp: [],
                chooseJobVisibleDown: false,
                chooseJobDown: [],

                AddDetailsVisible: false,
                AddDetails: {},
                AddAstrictsVisible: false,
                AddAstricts: {},

                AddAuthorityVisible:false,
                AddAuthority:{},

                AddKnowledgeRequireArryVisible:false,
                AddKnowledgeRequireArry:{},

                AddSkillRequireArryVisible:false,
                AddSkillRequireArry:{},

                AddManageRequireArryVisible:false,
                AddManageRequireArry:{},

                addUserVisible: false,
                choosedUsers: [],
            };
        },
        methods: {
            reload() {
                this.$forceUpdate()
            },
            // 选中一行显示
            onShowDlg(pid,Factory,PostName,DptName) {
                this.reload();
                // console.log("postExplain", this);
                console.log(pid);
                // if(Factory==""||Factory==null||Factory==undefined){
                //     Factory="";
                // }else{
                //     Factory=Factory;
                // }
                // if(PostName==""||PostName==null||PostName==undefined){
                //     PostName="";
                // }else{
                //     PostName=PostName;
                // }
                if (pid == "" || pid == null) {
                    this.operation=true;
                    this.dlgFrom = {
                        Factory: Factory,
                        DptName: DptName,
                        PostId: pid,
                        PostName: PostName,
                        
                        PostSuperiorsName: "",
                        PostSuperiorsId: "",
                        PostSubordinateId: "",
                        PostSubordinateName: "",
                        Details: [],
                        AssessItemArry: [],
                        AuthorityArry:[],
                        KnowledgeRequireArry:[],
                        SkillRequireArry:[],
                        ManageRequireArry:[],
                    };
                    this.dlgVisible = true;
                }
                else {
                    console.log(pid);
                    GetInfoByPostId({ id: pid }).then((res) => {
                        console.log(res.data.response);
                        if(res.data.response==undefined){
                            this.operation=true;
                            this.dlgFrom = {
                                Factory: Factory,
                                DptName: DptName,
                                PostId: pid,
                                PostName: PostName,
                                
                                PostSuperiorsName: "",
                                PostSuperiorsId: "",
                                PostSubordinateId: "",
                                PostSubordinateName: "",
                                Details: [],
                                AssessItemArry: [],
                                AuthorityArry:[],
                                KnowledgeRequireArry:[],
                                SkillRequireArry:[],
                                ManageRequireArry:[],
                            };
                        }else{
                            this.operation=false;
                            this.dlgFrom = res.data.response;
                        }
                    });
                    
                    this.dlgVisible = true;

                }
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgFrom);
                            console.log(this.operation)
                            if (this.operation) {
                                //新增
                                AddPostExplainInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            } else {
                                //修改
                                UpdatePostExplainInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "修改成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },

            onSubmit1(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgFrom);
                            console.log(this.operation)
                            // if (this.operation) {
                                //新增
                                AddInfoForTeam(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            // } 
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },

            // table opt
            RowAdd() {
                console.log("添加");
            },

            RowEdit(index, row) {
                console.log(index, row);
            },
            RowDelete(index, row) {
                console.log(index, row);
            },

            // chosePost
            chosePostcallFunction(newdata) {
                this.dlgFrom.PostName = newdata.Name;
                this.dlgFrom.PostId = newdata.Id;
                console.log(newdata);
                GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
                    if (res.data.code == 200) {
                        console.log(res.data);
                        if (res.data.data.deptName != null) {
                            this.dlgFrom.Factory = res.data.data.deptName;
                        } else {
                            this.dlgFrom.Factory = "";
                        }

                        this.dlgFrom.DptName = res.data.data.queryDeptName;
                    } else {
                        this.$message({
                            message: "获取部门信息出错",
                            type: "error",
                            duration: 5000,
                        });
                    }
                });
                console.log(this.dlgFrom);
                this.addPostVisible = false;
            },
            chosePost() {
                this.addPostVisible = true;
            },

            chooseJobUpPostcallFunction(newdata) {
                this.dlgFrom.PostSuperiorsName = newdata.Name;
                this.dlgFrom.PostSuperiorsId = newdata.Id;
                this.chooseJobVisibleUp = false;
            },
            
            chooseJobUpDlg() {
                this.chooseJobVisibleUp = true;
            },
            // AddUser Fucation
            checkupUsercallFunction(newdata) {
                if (newdata.length > 1) {
                    this.$message({
                        message: "不允许选择多人",
                        type: "error",
                    });
                    return;
                }
                this.dlgFrom.PostSuperiorsId = newdata[0].Id;
                this.dlgFrom.PostSuperiorsName = newdata[0].UserNameClaim;

                this.addUserVisible = false;
            },
            chooseUser() {
                this.addUserVisible = true;
            },

            chooseJobDownPostcallFunction(newdata) {
                this.dlgFrom.PostSubordinateName = newdata.Name;
                this.dlgFrom.PostSubordinateId = newdata.Id;
                this.chooseJobVisibleDown = false;
            },
            chooseJobDownDlg() {
                this.chooseJobVisibleDown = true;
            },

            ShowDetails() {
                this.AddDetailsVisible = true;
                this.AddDetails = { };
            },
            ShowAstricts() {
                this.AddAstrictsVisible = true;
                this.AddAstricts = { };
            },
            ShowAuthority(){
                this.AddAuthorityVisible=true;
                this.AddAuthority={};
            },
            ShowKnowledgeRequireArry(){
                this.AddKnowledgeRequireArryVisible=true;
                this.AddKnowledgeRequireArry={};
            },
            ShowSkillRequireArry(){
                this.AddSkillRequireArryVisible=true;
                this.AddSkillRequireArry={};
            },
            ShowManageRequireArry(){
                this.AddManageRequireArryVisible=true;
                this.AddManageRequireArry={};
            },

            DelDetails(index,row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.Details.splice(this.dlgFrom.Details.indexOf(row), 1);
                });
            },
            DelAstricts(index,row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.AssessItemArry.splice(this.dlgFrom.AssessItemArry.indexOf(row), 1);
                });
            },
            DelAuthority(index,row) {//Authority
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.AuthorityArry.splice(this.dlgFrom.AuthorityArry.indexOf(row), 1);
                });
            },
            DelKnowledgeRequireArry(index,row){
                this.$confirm("确认要删除吗？","提示",{
                    type:"warning",
                }).then((_)=>{
                    this.dlgFrom.KnowledgeRequireArry.splice(this.dlgFrom.KnowledgeRequireArry.indexOf(row),1);
                });
            },
            DelSkillRequireArry(index,row){
                this.$confirm("确认要删除吗？","提示",{
                    type:"warning",
                }).then((_)=>{
                    this.dlgFrom.SkillRequireArry.splice(this.dlgFrom.SkillRequireArry.indexOf(row),1);
                });
            },
            DelManageRequireArry(index,row){
                this.$confirm("确认要删除吗？","提示",{
                    type:"warning",
                }).then((_)=>{
                    this.dlgFrom.ManageRequireArry.splice(this.dlgFrom.ManageRequireArry.indexOf(row),1);
                });
            },
            EditDetails(index, row) {
                this.AddDetails = row;
                this.AddDetails.rowIndex = index;
                this.AddDetailsVisible = true;
            },
            EditAstricts(index, row) {
                this.AssessItemArry = row;
                this.AssessItemArry.rowIndex = index;
                this.AddAstrictsVisible = true;
            },
            EditAuthority(index, row) {
                this.AuthorityArry = row;
                this.AuthorityArry.rowIndex = index;
                this.AddAuthorityVisible = true;
            },
            EditKnowledgeRequireArry(index,row){
                this.KnowledgeRequireArry=row;
                this.KnowledgeRequireArry.rowIndex=index;
                this.AddKnowledgeRequireArryVisible=true;
            },
            EditSkillRequireArry(index,row){
                this.SkillRequireArry=row;
                this.SkillRequireArry.rowIndex=index;
                this.AddSkillRequireArryVisible=true;
            },
            EditManageRequireArry(index,row){
                this.ManageRequireArry=row;
                this.ManageRequireArry.rowIndex=index;
                this.AddManageRequireArryVisible=true;
            },

            DetailsSubmit(type) {
                console.log("this.dlgFrom.Details:", this.AddDetails)

                if (this.AddDetails.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddDetails);
                    this.$set(this.dlgFrom.Details, this.AddDetails.rowIndex, para);
                }
                else {  // 添加
                    console.log(this.dlgFrom.Details);
                    this.dlgFrom.Details.push(this.AddDetails);
                }
                this.AddDetailsVisible = false;
            },
            AstrictsSubmit() {
                if (this.AddAstricts.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddAstricts);
                    this.$set(this.dlgFrom.AssessItemArry, this.AddAstricts.rowIndex, para);
                }
                else {  // 添加
                    this.dlgFrom.AssessItemArry.push(this.AddAstricts);
                }
                this.AddAstrictsVisible = false;
            },
            AuthoritySubmit() {
                if (this.AddAuthority.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddAuthority);
                    this.$set(this.dlgFrom.AuthorityArry, this.AddAuthority.rowIndex, para);
                }
                else {  // 添加
                    this.dlgFrom.AuthorityArry.push(this.AddAuthority);
                }
                this.AddAuthorityVisible = false;
            },
            KnowledgeRequireArrySubmit() {
                if (this.AddKnowledgeRequireArry.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddKnowledgeRequireArry);
                    this.$set(this.dlgFrom.KnowledgeRequireArry, this.AddKnowledgeRequireArry.rowIndex, para);
                }
                else {  // 添加
                    this.dlgFrom.KnowledgeRequireArry.push(this.AddKnowledgeRequireArry);
                }
                this.AddKnowledgeRequireArryVisible = false;
            },
            SkillRequireArrySubmit() {
                if (this.AddSkillRequireArry.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddSkillRequireArry);
                    this.$set(this.dlgFrom.SkillRequireArry, this.AddSkillRequireArry.rowIndex, para);
                }
                else {  // 添加
                    this.dlgFrom.SkillRequireArry.push(this.AddSkillRequireArry);
                }
                this.AddSkillRequireArryVisible = false;
            },
            ManageRequireArrySubmit() {//ManageRequireArry
                if (this.AddManageRequireArry.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddManageRequireArry);
                    this.$set(this.dlgFrom.ManageRequireArry, this.AddManageRequireArry.rowIndex, para);
                }
                else {  // 添加
                    this.dlgFrom.ManageRequireArry.push(this.AddManageRequireArry);
                }
                this.AddManageRequireArryVisible = false;
            },
        },

    };
</script>

<style scoped>
    .tableTitle {
        position: relative;
        margin: 0 auto;
        height: 1px;
        background-color: #4cff00;
        text-align: center;
        font-size: 16px;
        color: rgba(101, 101, 101, 1);
    }
    .myAlertSuccess {
        background-color: #409eff;
        color: #FFF;
        padding: 8px 16px;
        border-radius: 4px ;
        text-align: center;
        font-size: 16px;
    }
</style>